

































































































import Vue from "vue";
import { getNWSettingMenus } from "@/views/nwSetting/NWSettingMain.vue";
import { getTrafficLogMenus } from "@/views/trafficLog/TrafficLogMain.vue";
import { NWInformationMenus } from "@/views/nwInformation/NwInformationMain.vue";
import { getOtherMenus } from "@/views/others/OthersMain.vue";
import { mapState } from "vuex";
import {
  SidebarComponentItem,
  SidebarHeaderItem,
  SidebarItem,
} from "vue-sidebar-menu";
import { ROLE_DELEGATE_VIEW_USER } from "@/apis/InformationApi";

export default Vue.extend({
  name: "Main",
  data() {
    return {
      contract: NWInformationMenus,
      logoImg: null as string | null,
      isLoaded: false,
    };
  },
  computed: {
    ...mapState("user", {
      isLogged: "isLogged",
      loginUser: "loginUser",
      contractInfo: "contractInfo",
      contractSummary: "contractSummary",
      contractAppliances: "contractAppliances",
      me: "me",
      vnumber: "vnumber",
    }),
    /** true: 一般ユーザー, false: 代行権限 */
    isNormalUser(): boolean {
      return this.$service.permission.isNormalUser();
    },
    /** NW設定メニュー */
    nwSetting(): Array<SidebarItem | SidebarComponentItem | SidebarHeaderItem> {
      return getNWSettingMenus(
        this.contractInfo.contractList,
        this.contractSummary,
        this.contractAppliances.applianceInfoList,
        this.$service.permission
      );
    },
    /** トラフィック/ログメニュー */
    trafficLog(): Array<
      SidebarItem | SidebarComponentItem | SidebarHeaderItem
    > {
      return getTrafficLogMenus(
        this.contractSummary,
        this.$service.permission,
        this.contractInfo.contractList
      );
    },
    /** 各種設定 */
    otherMenus(): Array<
      SidebarItem | SidebarComponentItem | SidebarHeaderItem
    > {
      return getOtherMenus(this.contractSummary, this.$service.permission);
    },
  },
  async mounted() {
    this.logoImg = await this.$api.information.getServiceLogo();
    const loginUser = await this.$api.information.getLoginUser();
    const contractInfo = await this.$api.contract.getInformation();
    const contractSummary = await this.$api.contract.getSummary();
    const contractAppliances = await this.$api.contract.getAppliances();
    if (loginUser.roleCode === ROLE_DELEGATE_VIEW_USER) {
      await this.$store.dispatch("user/setLoginInfo", {
        loginUser,
        contractInfo,
        contractSummary,
        contractAppliances,
      });
    } else {
      const users = await this.$api.user.getAll();
      await this.$store.dispatch("user/setLoginInfo", {
        loginUser,
        contractInfo,
        contractSummary,
        contractAppliances,
        users,
      });
    }
    this.isLoaded = true;
  },
  methods: {
    async logout() {
      if (this.isNormalUser) {
        const response = await this.$api.auth.logout();
        await this.$store.dispatch("user/logout");
        if (response.headers.location) {
          window.location.href = response.headers.location;
        } else {
          await this.$router.push({ path: "/login" });
        }
      } else {
        await this.$api.auth.logoutProxy();
        await this.$store.dispatch("user/logout");
        window.close();
      }
    },
  },
});
